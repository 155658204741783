import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import * as s from "./post_template.module.scss";

export default function PostTemplate({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout title={frontmatter.title}>
      <div className={s.header_block}>
        <h1 className={s.title}>{frontmatter.title}</h1>
        <h2 className={s.subtitle}>{frontmatter.date}</h2>
        <div
          className={s.markdown_content}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "YYYY MMMM DD")
        slug
        title
      }
    }
  }
`;
